import React from "react"
import { Input } from "reactstrap"

const SectionComponent = ({ question }) => {
  return (
    <>
      <div style={{
        width: "100%",
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>

        <span style={{
          fontSize: "1.7em",
          fontWeight: "bold",
          color: "#000000",
          marginBottom: "5px",
          marginTop: "-15px"
        }}>
          {question.text}

        </span>
        <div style={{
          display: "flex", justifyContent: "center", width: "100%"
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            border: "1px #AFAFAF solid",
            padding: "2px 5px",
            borderRadius: "10px",
            width: "100%",
            alignItems: "center",

          }} >
            <span style={{
              fontSize: "1.2em",
              color: "#00000050",
              marginTop: "10px",
              marginBottom: "10px",
              padding: "5px",
              textAlign: "center"

            }}>
              {question.help}
            </span >
          </div>
        </div >

      </div>
    </>
  )
}

export default SectionComponent