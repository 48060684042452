import Header from "components/Headers/Header";
import React, { Component } from "react";
import { Steps } from "antd";
import Rating from "react-rating";
import "antd/dist/antd.css";
import "./rating.css";
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	CardBody,
	Progress,
	Button,
	FormFeedback,
	Input,
	FormText,
	Form,
	FormGroup,
	Label,
	Spinner,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import { updateBasic } from "actions/authActions";
import ChangePassword from "components/ChangePassword";
class UpdatePassword extends Component {
	state = {
		isOpenPasswordForm: false
	}

	togglePasswordForm = () => {
		this.setState({ isOpenPasswordForm: !this.state.isOpenPasswordForm });
	}
	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question });
	};
	getRaters = () => {
		let { raters, uid } = this.props;
		return raters && raters.find((rat) => rat.id === uid);

	};

	render() {
		let { auth, raters, uid } = this.props;
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt--9" fluid>
					{/* forms */}
					<Row>
						<div className="col">
							<Card className=" shadow ">
								{/* <CardHeader className=" border-0 ">
									<div style={{ display: "flex" }}>
										<h3 className="ml-3 mb-0 ">Edit Profile</h3>
									</div>
								</CardHeader> */}

								{isLoaded(raters) && isLoaded(auth) ? (
									<CardBody className=" mb-0 ">
										<h4 className="mb-3 font-weight-bold">Edit Basic</h4>
										<Form
											key={auth.email}
											onSubmit={e => {
												e.preventDefault();
												let obj = {
													email: auth.email,
													firstname: e.target.fname.value,
													lastname: e.target.lname.value,
													positionId: e.target.type.value,
												};
												this.props.updateBasic(obj);
											}}
										>
											<Row>
												<Col md={6}>
													<FormGroup>
														<Label>First Name</Label>
														<Input
															type="text"
															name="fname"
															defaultValue={
																this.getRaters() &&
																this.getRaters().firstname
															}
														/>
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<Label>Last Name</Label>
														<Input
															type="text"
															name="lname"
															defaultValue={
																this.getRaters() &&
																this.getRaters().lastname
															}
														/>
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<Label>Email</Label>
														<Input
															type="text"
															name="email"
															disabled
															defaultValue={
																this.getRaters() &&
																this.getRaters().email
															}
														/>
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<FormGroup>
															<Label> Role </Label>
															<Input
																name="type"
																type="text"
																disabled
																defaultValue={this.getRaters()?.positionId}
															>
															</Input>
														</FormGroup>
													</FormGroup>
												</Col>
											</Row>
											<Button type="submit" color="default" className="mb-5">
												Update
											</Button>
										</Form>

										<Link onClick={() => {
											this.setState({
												isOpenPasswordForm: true
											})
										}} >Change Password </Link>
										<ChangePassword
											isOpen={this.state.isOpenPasswordForm}
											toggle={this.togglePasswordForm}

										/>
									</CardBody>
								) : (
									<div className="w-100 text-center py-3">
										<Spinner />
									</div>
								)}
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		auth: state.firebase.auth,
		raters: state.firestore.ordered.raters,
		components: state.firestore.ordered.components,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateBasic: basicObj => dispatch(updateBasic(basicObj)),
	};
};

export default compose(
	firestoreConnect([{ collection: "raters" }, { collection: "components" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdatePassword);
