import axios from "axios";

export const emailLogin = (email) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();

		firebase.auth().signInWithEmailLink(email, window.location.href).then((data) => {
			console.log(`login success  with email ${email}  `)
			dispatch({
				type: "LOGIN_SUCCESS",
				id_type: "rater",
				uid: data.user.uid,
				error: "",
			});
		}).catch((e) => {
			alert("The login link has expired, please login using email and password sent on the invitation email.")
			dispatch({
				type: "LOGIN_FAIL",
				id_type: "",
				uid: "",
				error: { message: "Invalid login email" },
			});
		})
	}
}
export const loginRater = credentials => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		dispatch({
			type: "IS_LOADING",
		});
		firebase
			.auth()
			.signInWithEmailAndPassword(credentials.email, credentials.password)
			.then(data => {
				return data.user.uid;
			})
			.then(data => {
				return firestore.collection("raters").doc(data).get();
			})
			.then(data => {
				if (data.data() === undefined) {
					dispatch({
						type: "LOGIN_FAIL",
						id_type: "",
						uid: "",
						error: { message: "You are not authorized to access this panel" },
					});
				} else {
					dispatch({
						type: "LOGIN_SUCCESS",
						id_type: "rater",
						uid: data.id,
						error: "",
					});
				}
				dispatch({
					type: "IS_LOADED",
				});
			})
			.catch(error => {
				dispatch({
					type: "LOGIN_FAIL",
					id_type: "",
					uid: "",
					error: error,
				});
				dispatch({
					type: "IS_LOADED",
				});
			});
	};
};
export const logout = () => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.signOut()
			.then(data => {
				localStorage.clear();
				dispatch({
					type: "LOGOUT_SUCCESS",
					id_type: "",
					uid: "",
					signup: false,
					error: "",
				});
			});
	};
};
export const signup = credentials => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		dispatch({
			type: "IS_LOADING",
		});
		let invited = false;
		let invitations = await firestore.collection('invitations').get();
		for (let doc of invitations.docs) {
			let data = doc.data();
			if (data.email === credentials.email)
				invited = true
		}

		if (invited) {
			firebase
				.auth()
				.createUserWithEmailAndPassword(credentials.email, credentials.password)
				.then(async (data) => {
					await firebase.auth().currentUser.updateProfile({
						displayName: credentials.firstname + " " + credentials.lastname
					})
					return data.user.uid;
				})

				.then(uid => {
					firestore
						.collection("raters")
						.doc(uid)
						.set({
							role: "rater",
							email: credentials.email,
							firstname: credentials.firstname,
							lastname: credentials.lastname,
							positionId: credentials.positionId,
						})
						.then(data => {


							dispatch({
								type: "SIGNUP_SUCCESS",
								id_type: "rater",
								uid: uid,
								error: "",
							});


							dispatch({
								type: "IS_LOADED",
							});
						})
						.catch(error => {
							dispatch({
								type: "SIGNUP_FAIL",
								id_type: "",
								uid: "",
								error: error,
							});
							dispatch({
								type: "IS_LOADED",
							});
						});
				})
				.catch(error => {
					dispatch({
						type: "SIGNUP_FAIL",
						id_type: "",
						uid: "",
						error: error,
					});
					dispatch({
						type: "IS_LOADED",
					});
				});

		}
		else {
			let error = { code: 404, message: "You are not Invited!" }
			dispatch({
				type: 'SIGNUP_FAIL',
				id_type: '',
				uid: '',
				error: error,
			});
			dispatch({
				type: 'IS_LOADED',
			});
		}
	};
};
export const verify = email => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();

		dispatch({
			type: 'IS_LOADING',
		});
		let invited = false;
		let invitations = await firestore.collection('invitations').get();
		for (let doc of invitations.docs) {
			let data = doc.data();
			if (data.email === email) { invited = true };
		}
		if (invited) {
			dispatch({
				type: 'VERIFY_SUCCESS',
				error: '',
			});
			dispatch({
				type: 'IS_LOADED',
			});
		} else {
			let error = { code: 404, message: 'You are not Invited!' };
			dispatch({
				type: 'VERIFY_FAIL',
				error: error,
			});
			dispatch({
				type: 'IS_LOADED',
			});
		}
	}
}

export const resetPassword = (email) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const link = window.location.origin;
		getFirebase().functions().httpsCallable("sendForgotPasswordLink")({
			email,
			continueUrl: link,
		})
			.then((data) => {
				dispatch({
					type: "SEND_FORGOT_PASSWORD_LINK_SUCCESS",
				});
			})
			.catch((error) => {
				console.log(error.message)

				dispatch({
					type: "SEND_FORGOT_PASSWORD_LINK_FAIL",
				});
			});
	};
};
export const updateBasic = basicObj => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	const firebase = getFirebase();
	const firestore = getFirestore();
	let { email, firstname, lastname, positionId } = basicObj;
	let id = -1;
	await firestore
		.collection("raters")
		.where("email", "==", email)
		.get()
		.then(docs => {
			docs.forEach(doc => {
				id = doc.id;
			});
		});
	if (id != -1) {
		await firestore
			.collection("raters")
			.doc(id)
			.update({
				firstname,
				lastname,
				positionId,
			})
			.then(() => {
				alert("Basic Information Updated");
			});
	}
};



