import authReducer from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { combineReducers } from "redux";
import updateReducer from "./updateReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	update: updateReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer,
});
export default rootReducer;
