import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import firebase from "firebase"
import { Alert, notification } from 'antd';

const ChangePassword = ({ isOpen, toggle, }) => {
  const [passwordDetails, setPasswordDetails] = useState({
    newPassword: null,
    confirmPassword: null,
    currentPassword: null
  })
  const [error, setError] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)


  const openNotification = (placement) => {
    notification.success({
      message: `Password changed successfully`,
      placement,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    let error = false
    if (!passwordDetails?.newPassword) {
      setError((error) => {
        return { ...error, initial_password_error: "Please enter new Password" }
      })
      error = true
    }
    else if (passwordDetails?.newPassword.length < 6) {
      setError((error) => {
        return { ...error, initial_password_error: "Password must be 6 characters long" }
      })

      error = true
    }

    else {
      setError((error) => {
        return { ...error, initial_password_error: "" }
      })
    }
    if (!passwordDetails?.confirmPassword) {
      setError((error) => {
        return { ...error, confirm_password_error: "Please enter Password" }
      })
      error = true
    }
    else if (passwordDetails?.newPassword !== passwordDetails?.confirmPassword) {
      setError((error) => {
        return { ...error, confirm_password_error: "Password did not match" }
      })

      error = true

    }
    else {
      setError((error) => {
        return { ...error, confirm_password_error: "" }
      })
    }
    if (!passwordDetails?.currentPassword) {
      setError((error) => {
        return { ...error, current_password_error: "Please enter current pasword" }
      })
      error = true
    } else {
      setError((error) => {
        return { ...error, current_password_error: "" }
      })
    }
    if (!error) {

      try {
        setIsLoading(true)
        const user = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(
          user.email,
          passwordDetails?.currentPassword
        );

        await user.reauthenticateWithCredential(cred);
        await user.updatePassword(passwordDetails?.newPassword)
        openNotification('top')
        toggle()
        setIsLoading(false)

      } catch (e) {
        setIsLoading(false)
        setErrorMessage("Something went wrong!")

      }

    }
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setPasswordDetails({
          newPassword: null,
          confirmPassword: null,
          currentPassword: null
        })
        setErrorMessage(null)
        setError({})
        toggle()
      }}
      size='sm'

    >
      <ModalHeader
        toggle={toggle}
      >
        Update Password
      </ModalHeader>
      <ModalBody>
        {errorMessage && (<Alert style={{ marginBottom: "8px" }} message={errorMessage} type="error" showIcon />)}
        <h5 className="text-danger" > Password must be 6 characters long </h5>
        <Form onSubmit={handleSubmit} >
          <FormGroup>
            <Label> Current Password</Label>
            <Input type="password"
              name="currentPassword"
              value={passwordDetails.currentPassword}
              valid={!!!error.current_password_error && passwordDetails.currentPassword}
              invalid={!!error.current_password_error}
              onChange={(e) => {
                setPasswordDetails({
                  ...passwordDetails,
                  currentPassword: e.target.value
                })
                setError((error) => {
                  return { ...error, current_password_error: "" }
                })
              }}
            />
            <FormFeedback invalid="true" > {error.current_password_error} </FormFeedback>

          </FormGroup>
          <FormGroup>
            <Label> New Password</Label>
            <Input type="password"
              name="newPassword"
              value={passwordDetails.newPassword}
              valid={!!!error.initial_password_error && passwordDetails.newPassword}
              invalid={!!error.initial_password_error}
              onChange={(e) => {
                setPasswordDetails({
                  ...passwordDetails,
                  newPassword: e.target.value
                })
                setError((error) => {
                  return { ...error, initial_password_error: "" }
                })
              }}
            />
            <FormFeedback invalid="true" > {error.initial_password_error} </FormFeedback>

          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              type="password"
              name="confirmPassword"
              value={passwordDetails?.confirmPassword}
              valid={!!!error.confirm_password_error && passwordDetails.confirmPassword}
              invalid={!!error.confirm_password_error}
              onChange={(e) => {
                {
                  setPasswordDetails({
                    ...passwordDetails,
                    confirmPassword: e.target.value
                  })
                  setError((error) => {
                    return { ...error, confirm_password_error: "" }
                  })
                }
              }
              }

            />
            <FormFeedback invalid="true" > {error.confirm_password_error} </FormFeedback>
          </FormGroup>

          <Button type="submit" color="default" >
            {isLoading ? <Spinner /> : "Update"}
          </Button>

        </Form>
      </ModalBody>

    </Modal>
  )
}

export default ChangePassword;