const initialState = {
	update: false,
};

export const updateReducer = (state = initialState, action) => {
	switch (action.type) {
		case "UPDATE":
			return { ...state, update: !state.update };
		default:
			return { ...state };
	}
};

export default updateReducer;
