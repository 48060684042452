import { addAnswer, changeStatus, editAnswer } from "actions/raterActions";
import { update } from "actions/updateActions";
import { Steps } from "antd";
import "antd/dist/antd.css";
import Header from "components/Headers/Header";
import QuestionComponent from "components/ratingComponents/QuestionComponent"
import React, { useEffect, useState } from "react";
import { Alert } from 'antd';
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import useMediaQuery from "hooks/useMediaQuery";
// reactstrap components
import {

  Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody,
  ModalFooter, ModalHeader, Progress, Row, Spinner
} from "reactstrap";
import { compose } from "redux";
import "./rating.css";
const { Step } = Steps;

export function NewRaterAssessments(props) {

  const [assessment, setAssessment] = useState(null)
  const [invitation, setInvitation] = useState(null)
  const [showConfiramtionDialog, setShowConfirmationDialog] = useState(false)
  const [questionIndex, setQuestionIndex] = useState(null)
  const [maxQuestionIndex, setMaxQuextionIndex] = useState(0)
  const [answers, setAnswers] = useState(null)
  const [error, setError] = useState({})
  const isSmall = useMediaQuery('(max-width: 1240px)');
  const [leader, setLeader] = useState(null)

  useEffect(() => {
    if (invitation && assessment) {
      const validQuestionsIds = assessment?.questions?.map((que, index) => {
        return que.id ?? index
      })
      Object.keys(invitation?.answers ?? {}).forEach((queKey) => {
        if (validQuestionsIds.includes(queKey)) {
          setAnswers((oldAnswers) => {
            return {
              ...oldAnswers,
              [queKey]: invitation.answers[queKey]
            }
          })

        }
      })

    }
  }, [invitation?.answers, assessment])

  useEffect(() => {
    if (questionIndex === null && invitation) {
      setQuestionIndex(invitation?.questionIndex ?? 0)
    }
  }, [invitation])

  useEffect(() => {
    if (invitation && answers) {
      props.addAnswer(answers, invitation.id, questionIndex);
    }
    if (questionIndex > maxQuestionIndex) {
      setMaxQuextionIndex(questionIndex)
    }

    window.location.hash = `#step${questionIndex - 1}`;
  }, [questionIndex])


  useEffect(() => {
    const { uid, assessments, invitations, raters } = props;

    const rater = raters && raters.find((rat) => rat.id === uid);

    Sentry.setUser({
      email: rater?.email,
      username: rater?.firstname,
      id: rater?.id
    })

    const { clientId, projectId, assessmentId } = props.match.params

    const assessment =
      assessments && assessments.find((ass) => ass.id === assessmentId);
    const invitation =
      invitations &&
      invitations.find((inv) => {
        return (
          rater &&
          inv.email === rater.email &&
          inv.assessmentId === assessmentId &&
          inv.projectId === projectId &&
          inv.clientId === clientId
        );
      });
    setAssessment(assessment)
    setInvitation(invitation)
    if (invitation && invitation.status === "invited") {
      props.changeStatus("accepted", invitation.id);
    }
  }, [props.invitations, props.assessments, props.clients])

  useEffect(() => {
    const { clientId, projectId, assessmentId } = props.match.params
    if (props.clients && assessment) {
      const client = props.clients.find((c) => c.id === clientId);
      const project = client?.projects.find((p) => p.id === projectId)
      const owner = project?.owners && project?.owners[assessmentId]
      setLeader(owner)
    } else {
      setLeader(null)
    }

  }, [assessment, props.clients])

  function getCurrentQuestion() {
    return assessment?.questions[questionIndex]
  }

  function toggleConfirmSubmission() {
    setShowConfirmationDialog((previousValue) => !previousValue)
  };

  function submitAnswer() {
    props.addAnswer(answers, invitation.id, questionIndex);
  }

  if (!assessment || !invitation) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }} >
        <Spinner color="primary" />
      </div>
    )
  }

  function handleClickOnNext() {
    const question = getCurrentQuestion()
    if (question?.type === "SCALE") {
      if (!answers?.[question?.id]) {
        setError((error) => {
          return { ...error, input_error: "Please select value between 1 and 10" }
        })
        return;
      }
    } else if (question?.type === "FREE_TEXT") {
      if (!answers?.[question?.id]) {
        setError((error) => {
          return { ...error, input_error: "Your answer is required to continue." }
        })
        return;
      }
    }
    setError((error) => {
      return { ...error, input_error: "" }
    })

    if (questionIndex === assessment.questions.length - 1) {
      toggleConfirmSubmission();
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }

  const ShowLeaderInfoDiv = () => {
    return (
      <div style={{ display: "flex", marginLeft: `${!isSmall && "30px"}`, justifyContent: "center", width: "100%" }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          border: "1px #13B2EE solid",
          padding: `${isSmall ? "4px" : "2px 5px"}`,
          borderRadius: "10px",
          marginTop: "-10px",
          marginRight: `${!isSmall && "40px"}`,
          width: "100%",
          alignItems: "center",
          marginLeft: `${!isSmall && "70px"}`
        }} >
          {assessment?.type === "Leader" && (<span> You are offering feedback to:  <span style={{ fontWeight: "bold", fontSize: "16px" }}> {`${leader?.firstName} ${leader?.lastName}`} </span>  </span>)}
        </div>
      </div >
    )

  }
  const getProgressValue = () => {
    const validQuestionLength = assessment.questions.filter((q) => q.type !== "SECTION").length
    const progress = (questionIndex) /
      validQuestionLength * 100;
    return progress
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9" style={{ width: "100%", height: "100%" }} >
        <Card className=" shadow " style={{ height: !isSmall && "100vh", display: "flex" }}  >
          <Progress
            style={{ width: "100%" }}
            color="info"
            value={getProgressValue()}
          />
          <CardHeader className=" border-0" style={{ padding: "1rem" }} >
            <div style={{ display: "flex" }}>

              <Link
                to="/rater/projects/assessments"
                className="text-default "
              >
                <i
                  className="fas fa-long-arrow-alt-left "
                  style={{ fontSize: "25px" }}
                />
              </Link>

              <h3 className="ml-3 mb-0 ">Assessment</h3>
              {!isSmall && (<ShowLeaderInfoDiv />)}

            </div>
          </CardHeader>

          <CardBody
            style={{ padding: "1rem", display: "flex", flexDirection: `${isSmall ? "column" : "row"}`, marginTop: "3px", flex: 1, overflowY: "hidden" }}>
            {/* showing steps */}
            {isSmall && (<ShowLeaderInfoDiv />)}
            {isSmall && <div style={{ height: "40px" }}> </div>}
            <Steps
              progressDot
              style={{ flex: "2", overflowX: isSmall ? "scroll" : "hidden", overflowY: isSmall ? "hidden" : "scroll" }}
              direction={isSmall ? "horizontal" : "vertical"}
              size="small"
              current={questionIndex}
              onChange={(v) => {
                if (maxQuestionIndex >= v) {
                  setQuestionIndex(v)
                } else {
                  setQuestionIndex(maxQuestionIndex)
                }

              }}
              responsive={false}
            >
              {assessment.questions.map((question, index) => {
                let title, description;
                if (question.type === "SECTION") title = question?.sectionName ?? "Section"
                else title = question?.competancy
                description = question?.competency
                return (
                  <Step
                    id={"step" + index}
                    key={description + index}
                    title={title}
                    description={description}
                  />
                );
              })}
            </Steps>

            {isSmall && <div style={{ height: "40px" }}> </div>}
            <div style={{ display: "flex", flex: "7", flexDirection: "column" }}>
              {/* Question Component */}
              <QuestionComponent
                key={getCurrentQuestion()?.id ?? questionIndex}
                question={getCurrentQuestion()}
                questionNumber={questionIndex + 1}
                value={answers?.[getCurrentQuestion()?.id ?? questionIndex]}
                leader={leader}
                onChange={(value) => {
                  setAnswers({
                    ...answers,
                    [getCurrentQuestion().id ?? questionIndex]: value
                  })
                  setError((error) => {
                    return { ...error, input_error: "" }
                  })


                }}
              />
              {error.input_error && (
                <div style={{ display: "flex", justifyContent: "center" }} >
                  <Alert type="error" message={error.input_error} showIcon >  </Alert>
                </div>
              )}
              <Row style={{ display: "flex", justifyContent: "space-between", marginLeft: "5px", marginRight: `${isSmall ? "5px" : "40px"}`, marginTop: "20px" }}>
                <Button
                  color="default"
                  disabled={
                    questionIndex === 0
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (Object.keys(invitation.answers ?? {}).length + questionIndex > 0) {
                      setQuestionIndex(questionIndex - 1)
                    }
                    setError((error) => {
                      return { ...error, input_error: "" }
                    })
                  }}
                >
                  Previous
                </Button>
                <Button
                  onClick={handleClickOnNext}
                  color={questionIndex === (assessment.questions.length - 1) ? "success" : "default"}
                  size="md"
                >
                  {questionIndex === (assessment.questions.length - 1)
                    ? "Finish"
                    : "Next"}
                </Button>
              </Row>
            </div>
          </CardBody>
        </Card>


      </Container>

      <Modal
        isOpen={showConfiramtionDialog}
        toggle={toggleConfirmSubmission}
        className={props.className}
      >
        <ModalHeader
          toggle={() => {
            toggleConfirmSubmission();
          }}
        >
          Confirm Submission
        </ModalHeader>
        <ModalBody>
          You are about to submit your responses to the assessment.
          Upon clicking ‘Confirm’ you will no longer be able to edit them.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.changeStatus("completed", invitation.id);
              submitAnswer()
              toggleConfirmSubmission();
              props.history.push("/rater/projects/assessments");
            }}
          >
            Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              toggleConfirmSubmission();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    authError: state.auth.authError,
    consultants: state.firestore.ordered.consultants,
    clients: state.firestore.ordered.clients,
    raters: state.firestore.ordered.raters,
    consultants: state.firestore.ordered.consultants,
    invitations: state.firestore.ordered.invitations,
    assessments: state.firestore.ordered.assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer: (answerObj, invitationId, questionIndex) =>
      dispatch(addAnswer(answerObj, invitationId, questionIndex)),
    editAnswer: (index, answer, invitationId) =>
      dispatch(editAnswer(index, answer, invitationId)),
    changeStatus: (status, invitationId) =>
      dispatch(changeStatus(status, invitationId)),
    update: () => dispatch(update()),
    // deleteAssessment: (ass, projectId, clientId) =>
    //   dispatch(deleteAssessment(ass, projectId, clientId)),
  };
};

export default compose(
  firestoreConnect([
    { collection: "raters" },
    { collection: "consultants" },
    { collection: "invitations" },
    { collection: "assessments" },
    { collection: "clients" },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(NewRaterAssessments);