import React, { Component } from "react";
import RaterLayout from "layouts/Rater.js";
import AuthLayout from "layouts/Auth.js";
import firebase from "firebase"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import "./app.css";
import { Spinner } from "reactstrap";
import { emailLogin } from "actions/authActions";
export class app extends Component {

	state = {
		userFetched: false
	}

	async componentDidMount() {
		firebase.auth().onAuthStateChanged(async (user) => {
			const urlParams = new URLSearchParams(window.location.search);
			const email = decodeURIComponent(urlParams.get('email'));
			const oobCode = urlParams.get('oobCode')
			if (email && oobCode && user?.email !== email) {
				if (user) {
					localStorage.clear()
					await firebase.auth().signOut()
				}
				else {
					this.props.emailLogin(email)
				}
			}
			this.setState({
				userFetched: true
			})
		})
	}

	render() {
		if (!this.state.userFetched) {
			return (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }} >
					<Spinner color="primary" />
				</div>
			)
		}
		return (
			<BrowserRouter>
				<Switch>
					<Route
						path="/rater"
						render={props => (
							<div>
								<RaterLayout {...props} />
							</div>
						)}
					/>
					<Route
						path="/auth"
						render={props => (
							<div>
								<AuthLayout {...props} />
							</div>
						)}
					/>
					<Redirect from="*" to="/auth/login" />
				</Switch>
			</BrowserRouter>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		id_type: state.auth.id_type,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		emailLogin: (email) => dispatch(emailLogin(email)),
	};
};


export default compose(
	firestoreConnect([
		{ collection: "users" },
		{ collection: "products" },
		{ collection: "reviews" },
		{ collection: "announcements" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(app);
