import { logout } from "actions/authActions";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
// reactstrap components
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Button,
	Media,
} from "reactstrap";
import { compose } from "redux";

class AdminNavbar extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.parse(localStorage.getItem("assessment_title")) != null &&
			JSON.parse(localStorage.getItem("no_render")) === null
		) {
			localStorage.setItem("no_render", JSON.stringify({ ac: "yes" }));
			// this.setState({ ...this.state });
		}
	}

	render() {
		const { uid, consultants, raters } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);



		return (
			<>
				<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
					<Container fluid>
						<p className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
						</p>
						<Nav className="align-items-center d-none d-md-flex" navbar>
							<Button
								onClick={e => {
									e.preventDefault();
									this.props.logout();
								}}
							>
								Logout
							</Button>

							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
										{/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/dummy-avatar.jpg")}
                      />
                    </span> */}
										{/* <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm text-capitalize font-weight-bold">
                        {thisConsultant
                          ? thisConsultant.first_name
                          : thisRater
                          ? ""
                          : ""}
                      </span>
                    </Media> */}
									</Media>
								</DropdownToggle>
								{/* <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.logout();
                    }}
                  >
                    <i className="ni ni-user-run" />

                    <span>Logouts</span>
                  </DropdownItem>
                </DropdownMenu> */}
							</UncontrolledDropdown>
						</Nav>
					</Container>
				</Navbar>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth.authError,
		consultants: state.firestore.ordered.consultants,
		invitations: state.firestore.ordered.invitations,
		raters: state.firestore.ordered.raters,
		update: state.update,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logout()),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "users" },
		{ collection: "consultants" },
		{ collection: "raters" },
		{ collection: "invitations" }
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminNavbar);
