import raterAssessments from "views/examples/raterAssessments";
import Logout from "views/examples/Logout.js";
import Login from "views/examples/Login.js";
import EditProfile from "views/examples/EditProfile";

var raterRoutes = [
	{
		path: "/assessments",
		name: "Assessments",
		icon: "fa fa-edit text-primary",
		component: raterAssessments,
		layout: "/rater",
	},
	{
		path: "/edit-profile",
		name: "Edit Profile",
		icon: "fas fa-user-edit text-primary",
		component: EditProfile,
		layout: "/rater",
	},
];
export default raterRoutes;