import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { getFirebase } from "react-redux-firebase";
import rootReducer from "./reducers/rootReducer";
import { firebaseConfig } from "./firebase";
import firebase from "./firebase";

const saveToLocalStorage = state => {
	let ls = JSON.parse(localStorage.getItem("auth_rater"));
	if (ls === null || ls === "") {
		const serializedUid = JSON.stringify(state.auth.uid);
		localStorage.setItem("auth_rater", serializedUid);
	}
};

const checkLocalStorage = () => {
	const serializedUid = localStorage.getItem("auth_rater");
	if (serializedUid === null) return undefined;
	return {
		auth: {
			uid: JSON.parse(serializedUid),
			isLoad: false,
			verify: false,
		},
	};
};

export const store = createStore(
	rootReducer,
	checkLocalStorage(),
	composeWithDevTools(
		compose(
			applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
			reduxFirestore(firebase, firebaseConfig)
		)
	)
);

store.subscribe(() => saveToLocalStorage(store.getState()));
