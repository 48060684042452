import { signup, verify } from 'actions/authActions';
import firebase from "firebase";
import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
// reactstrap components
import {
	Button,
	Card,
	CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon,
	InputGroupText, Row, Spinner
} from 'reactstrap';
import { compose } from 'redux';


class SignUp extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		email: '',
	};
	signup = e => {
		e.preventDefault();
		if (e.target.password.value === e.target.confirm_password.value) {
			this.props.signup({
				firstname: e.target.firstname.value,
				lastname: e.target.lastname.value,
				email: e.target.email.value,
				password: e.target.password.value,
				positionId: e.target.type.value,
			});
		}
	};
	verify = async e => {
		e.preventDefault();
		let email = e.target.email.value;
		await this.props.verify(email);
		if (this.props.verifyCheck) {
			this.setState({ email })
		}
	}
	render() {
		let user = firebase.auth().currentUser


		return (
			<>
				<Col lg='5' md='7'>
					<Card className='bg-secondary shadow border-0'>
						<CardBody className='px-lg-5 py-lg-5'>
							<div className='text-center text-muted mb-4'>
								<small>Sign Up</small>
							</div>
							{!this.props.verifyCheck ? (
								<Form
									onSubmit={this.verify}
									key={'VerfiyForm'}
									role='form'
								>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-email-83' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Email'
												type='email'
												name='email'
												autoComplete='new-email'
											/>
										</InputGroup>
									</FormGroup>
									{this.props.authError ? (
										<h3 className='text-danger'>
											{' '}
											{this.props.authError}
										</h3>
									) : null}
									<div className='text-center'>
										<Button
											className='my-4'
											color='primary'
										>
											{this.props.isLoad === false ? (
												'Proceed'
											) : (
												<Spinner />
											)}
										</Button>
									</div>
								</Form>
							) : (
								<Form
									onSubmit={this.signup}
									key={'SignUpForm'}
									role='form'
								>
									<input type="hidden" name="email" value={this.state.email}></input>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-circle-08' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='First Name'
												type='text'
												name='firstname'
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-circle-08' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Last Name'
												type='text'
												name='lastname'
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-lock-circle-open' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Password'
												type='password'
												name='password'
												autoComplete='new-password'
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-lock-circle-open' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Confirm Password'
												type='password'
												name='confirm_password'
												autoComplete='new-password'
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Input name='type' type='select'>
											<option value=''>
												--Role Type--
											</option>
											{this.props.components &&
												this.props.components[7] &&
												this.props.components[7].role.map(
													role => (
														<option value={role}>
															{role}
														</option>
													)
												)}
										</Input>
									</FormGroup>
									<Row className='mt-3'>
										<Col xs='6' className='text-left'>
											<Link to='/auth/login'>
												<small>Login</small>
											</Link>
										</Col>
									</Row>
									{this.props.authError ? (
										<h3 className='text-danger'>
											{' '}
											{this.props.authError}
										</h3>
									) : null}
									<div className='text-center'>
										<Button
											className='my-4'
											color='primary'
										>
											{this.props.isLoad === false ? (
												'Sign Up'
											) : (
												<Spinner />
											)}
										</Button>
									</div>
								</Form>
							)}
						</CardBody>
					</Card>
				</Col>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		isLoad: state.auth.isLoad,
		authError: state.auth.authError,
		clients: state.firestore.ordered.clients,
		raters: state.firestore.ordered.raters,
		components: state.firestore.ordered.components,
		verifyCheck: state.auth.verify
	};
};
const mapDispatchToProps = dispatch => {
	return {
		signup: credentials => dispatch(signup(credentials)),
		verify: email => dispatch(verify(email))
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'users' },
		{ collection: 'clients' },
		{ collection: 'raters' },
		{ collection: 'components' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
