
import { loginRater } from "actions/authActions";
import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

// reactstrap components
import {
	Button,
	Card,
	CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon,
	InputGroupText, Row, Spinner
} from "reactstrap";

class Login extends React.Component {
	constructor(props) {
		super(props);
	}
	login = e => {
		e.preventDefault();
		this.props.loginRater({
			email: e.target.email.value,
			password: e.target.password.value,
		})
	};
	render() {
		const { authError, uid } = this.props;

		if (uid) {
			return <Redirect to="/rater/assessments" />
		}
		return (
			<>
				<Col lg="5" md="7">
					<Card className="bg-secondary shadow border-0">
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center text-muted mb-4">
								<small>Sign in </small>
							</div>
							<Form onSubmit={this.login} role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="email"
											placeholder="Email"
											type="email"
											autoComplete="new-email"
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-lock-circle-open" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="password"
											placeholder="Password"
											type="password"
											autoComplete="new-password"
										/>
									</InputGroup>
								</FormGroup>
								<Row className="mt-3">
									<Col xs="6">
										<Link className="text-center" to="/auth/forget-password">
											<small>Forgot password?</small>
										</Link>
									</Col>
									{/* <Col xs="6" className="text-right">
										<Link to="/auth/signup">
											<small>Sign Up</small>
										</Link>
									</Col> */}
								</Row>
								{authError ? (
									<h3 className="text-danger"> {authError}</h3>
								) : null}
								<div className="text-center">
									<Button className="my-4" color="primary">
										{/* {this.props.isLoad.isLoad==false ? 'no':'eyss'} */}

										{/* {JSON.stringify(this.props.isLoad.isLoad)=='false' ? 'no':'eyss'} */}

										{this.props.isLoad === false ? "Sign in" : <Spinner />}
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoad: state.auth.isLoad,
		uid: state.firebase.auth.uid,
		authError: state.auth.authError,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		loginRater: credentials => dispatch(loginRater(credentials)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
