import React from "react"
import { Input } from "reactstrap"

const TextQuestionComponent = ({ question, onChange, value }) => {
  return (
    <>
      <h3>
        {question?.text}
      </h3>
      {question?.help && (
        <div style={{ marginBottom: "10px", marginTop: "10px" }} >
          <i> Hint:{" "}
            {question?.help}
          </i>

        </div>
      )}
      <Input
        type="textarea"
        className="mb-4"
        rows={5}
        maxLength={1000}
        value={value}
        required
        onChange={(event) => {

          onChange(event.target.value)
        }}
      />
    </>
  )
}

export default TextQuestionComponent