import React from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import SectionComponent from './SectionComponent'
import SliderComponent from "./SliderComponent"
import TextQuestionComponent from './TextQuestionComponent'
import useMediaQuery from "hooks/useMediaQuery"
import * as Mustache from "mustache"

const QuestionComponent = ({ questionNumber, question, onChange, value, leader }) => {
  const isSmall = useMediaQuery('(max-width: 1240px)');

  function format(content) {
    return content?.split("*|").join("{{").split("|*").join("}}")
  }
  const data = {
    "LeaderFirstName": leader?.firstName,
    "LeaderLastName": leader?.lastName,
    "LeaderEmail": leader?.email

  }
  const questionText = Mustache.render(format(question?.text ?? ""), data)
  const questionHelp = Mustache.render(format(question?.help ?? ""), data)
  const newQuestion = {
    ...question,
    text: questionText,
    help: questionHelp ?? question?.help

  }



  function getQuestionComponent() {
    switch (question?.type) {
      case 'SCALE':
      case 'Scale 1 to 5':
        return <SliderComponent question={newQuestion} onChange={onChange} value={value} />
      case 'FREE_TEXT':
        return <TextQuestionComponent question={newQuestion} onChange={onChange} value={value} />
      case 'SECTION':
        return <SectionComponent question={newQuestion} />
      default:
        return <TextQuestionComponent question={newQuestion} onChange={onChange} value={value} />
    }
  }
  return (
    <div style={{ paddingRight: `${isSmall ? "0px" : "40px"}`, marginLeft: "0px", marginTop: "-10px" }} >
      {getQuestionComponent()}
    </div>
  )
}

export default QuestionComponent


