import { changeStatus } from 'actions/raterActions';
import { update } from 'actions/updateActions';
import Header from 'components/Headers/Header';
import useMediaQuery from 'hooks/useMediaQuery';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
// reactstrap components
import {
	Button, Card, CardBody, CardHeader, Col, Container, Media, Progress, Row, Table, Spinner
} from 'reactstrap';
import { compose } from 'redux';
import moment from 'moment';
class raterAssessments extends Component {
	state = {
		Question: false,
	};
	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question });
	};


	getAssessmentOwner = (clientId, projectId, assessmentId) => {
		if (!this.props.clients) return null
		const client = this.props.clients.find((c) => c.id === clientId);
		if (!client) return null
		const project = client.projects.find((p) => p.id === projectId)
		if (!project) return null;
		const owner = project.owners && project.owners[assessmentId]
		return owner
	}

	getAssessmentProject = (clientId, projectId) => {
		if (!this.props.clients) return null
		const client = this.props.clients.find((c) => c.id === clientId);
		if (!client) return null
		const project = client.projects.find((p) => p.id === projectId)
		return project
	}

	getAssessmentClient = (clientId) => {
		if (!this.props.clients) return null
		const client = this.props.clients.find((cli) => cli.id === clientId)
		return client

	}

	getProgessValue = (assessment, invitation) => {
		const validQuestionsIds = assessment?.questions?.filter((que) => que.type !== "SECTION").map((que, index) => {

			return que.id ?? index


		})

		const validAnswersIds = Object.keys(invitation?.answers ?? {}).filter((queKey) => {
			return validQuestionsIds?.includes(queKey)
		})
		return (validAnswersIds?.length / validQuestionsIds?.length)
	}


	render() {
		const { uid, assessments, invitations, raters, clients, consultants } = this.props;
		const thisRater = raters && raters.find(rat => rat.id === uid);
		const myInvitations = invitations && invitations.filter(inv => inv.email === thisRater?.email)
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<Card className=' shadow '>
						<CardHeader className=' border-0 '>
							<h3 className='ml-3 mb-0 '> Assessments  </h3>
						</CardHeader>
						<CardBody className=' mb-0 '>
							<InvitationDisplay assessments={assessments} myInvitations={myInvitations} getAssessmentClient={this.getAssessmentClient} getAssessmentOwner={this.getAssessmentOwner} getAssessmentProject={this.getAssessmentProject} getProgessValue={this.getProgessValue} />
						</CardBody>
					</Card>
				</Container>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth.authError,
		raters: state.firestore.ordered.raters,
		consultants: state.firestore.ordered.consultants,
		invitations: state.firestore.ordered.invitations,
		assessments: state.firestore.ordered.assessments,
		clients: state.firestore.ordered.clients,
		consultingCompanies: state.firestore.ordered.consulting_companies
	};
};
const mapDispatchToProps = dispatch => {
	return {
		changeStatus: (status, invitationId) =>
			dispatch(changeStatus(status, invitationId)),
		update: () => dispatch(update()),
		// addAssessment: (ass, projectId, clientId) =>
		//   dispatch(addAssessment(ass, projectId, clientId)),
		// deleteAssessment: (ass, projectId, clientId) =>
		//   dispatch(deleteAssessment(ass, projectId, clientId)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'raters' },
		{ collection: 'consultants' },
		{ collection: 'invitations' },
		{ collection: 'assessments' },
		{ collection: 'clients' },
		{ collection: 'consulting_companies' }
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(raterAssessments);


function InvitationDisplay({ assessments, myInvitations, getAssessmentOwner, getAssessmentProject, getProgessValue, getAssessmentClient }) {
	const isSmall = useMediaQuery('(max-width: 1240px)');

	if (!myInvitations) {
		return (

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "20vh" }} >
				<Spinner color="primary" />
			</div>
		)
	}

	if (myInvitations?.length === 0) {

		return (

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "20vh" }} >
				<h4> You have not been invited to any assessment yet!</h4>
			</div>
		)
	}

	if (!isSmall) {
		return (
			<Table
				className='align-items-center  table-flush '
				responsive
			>
				<thead className='thead-light'>
					<tr>
						<th scope='col'>
							Id
						</th>
						<th scope='col'>Client</th>
						<th scope='col'>Project</th>
						<th scope='col'> Progress</th>
						<th scope='col'>Leader</th>
						<th scope='col'>End Date</th>
						<th scope='col'>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						myInvitations
							?.map(inv => {
								var thisAssessment =
									assessments &&
									assessments.find(
										ass =>
											ass.id ===
											inv.assessmentId
									);

								const owner = getAssessmentOwner(inv.clientId, inv.projectId, inv.assessmentId)
								const project = getAssessmentProject(inv.clientId, inv.projectId)
								const client = getAssessmentClient(inv.clientId)
								return (
									<tr key={inv.id} >
										<th scope='row'>
											<Media className='align-items-center'>
												<Media>
													<span className='mb-0 text-sm'>
														{myInvitations.indexOf(inv) + 1}
													</span>
												</Media>
											</Media>
										</th>
										<td>
											{client?.name ?? "---"}
										</td>
										<td>

											{project?.name}
										</td>
										<td>
											<div style={{ display: "flex" }} className="align-items-center" >

												<Progress
													style={{
														width: '180px',
														marginRight: "5px"
													}}
													color='success'
													value={
														(getProgessValue(thisAssessment, inv) * 100)

													}
												/>

												{Math.round((getProgessValue(thisAssessment, inv) * 100))} %

											</div>
										</td>

										<td>
											{owner ? (owner.firstName + " " + owner.lastName) : "---"}
										</td>
										<td> {project?.end_date} </td>
										<td>
											<Link
												to={
													'/rater/client/' +
													inv?.clientId +
													'/project/' +
													inv?.projectId +
													'/assessment/' +
													inv?.assessmentId
												}

											>
												<Button
													disabled={inv?.status === "completed" || moment().isAfter(project?.end_date, 'day')}
													color='default'
													size='sm'
												>
													{
														(thisAssessment?.questions.length === 0 ? 'No Question Yet' :
															inv.status === "completed" ? 'Completed' :
																moment().isAfter(project?.end_date, 'day') ? 'Closed' : Object.keys(inv?.answers ?? {})?.length === 0 ? "Start" : 'Resume')

													}
												</Button>
											</Link>
										</td>
									</tr>
								);
							})}
				</tbody>
			</Table>
		)
	}

	return <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gridGap: '20px' }}>
		{
			myInvitations.map(inv => {
				let thisAssessment = assessments?.find(
					ass =>
						ass.id ===
						inv.assessmentId
				);

				const owner = getAssessmentOwner(inv.clientId, inv.projectId, inv.assessmentId)
				const project = getAssessmentProject(inv.clientId, inv.projectId)
				const client = getAssessmentClient(inv.clientId)
				const progress = getProgessValue(thisAssessment, inv)

				return (
					<Card key={project?.id} style={{ border: '1px solid #eaeaea', padding: '16px 20px' }}>
						<h1>
							{client?.name || '--'}
						</h1>

						<h3><b>{project?.name || '--'}</b> {owner ? `for ${owner ? (owner.firstName + " " + owner.lastName) : "--"}` : null}</h3>
						<em>{project?.description || '--'}</em> <em>End Date: {project?.end_date || '--'} </em>

						<div style={{ display: 'flex', alignItems: 'baseline' }}>


							<Progress
								style={{
									width: '150px',
								}}
								color='success'
								value={
									(getProgessValue(thisAssessment, inv) * 100)

								}
							/>
							<div style={{ marginLeft: '8px' }}>{(Math.round(getProgessValue(thisAssessment, inv) * 100))} %</div>
						</div>

						<Link
							to={
								'/rater/client/' +
								inv?.clientId +
								'/project/' +
								inv?.projectId +
								'/assessment/' +
								inv?.assessmentId
							}

						>
							<Button
								disabled={inv?.status === "completed" || moment().isAfter(project?.end_date, 'day')}
								color='default'
								size='sm'
							>
								{
									(thisAssessment?.questions.length === 0 ? 'No Question Yet' :
										inv.status === "completed" ? 'Completed' :
											moment().isAfter(project?.end_date, 'day') ? 'Closed' : Object.keys(inv?.answers ?? {})?.length === 0 ? "Start" : 'Resume')

								}
							</Button>
						</Link>
						{moment().isAfter(project?.end_date, 'day') &&
							<p style={{ color: "red", fontSize: "10px", padding: "4px" }} > This assessment is currently closed. Please contact <a target='blank' href='https://www.gmail.com'  >support@integralagility.com</a> if necessary.</p>}
					</Card >
				)


			})
		}
	</div>
}