import React from "react";
import { resetPassword } from "actions/authActions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from 'react-router-dom';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Spinner,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Row,
} from "reactstrap";
import { compose } from "redux";

class ForgetPassword extends React.Component {
	resetPassword = e => {
		e.preventDefault();
		this.props.resetPassword(e.target.email.value);
		alert("An email has been sent to your email, please follow the instructions on the email.")
		this.props.history.push("/auth/login");
	};
	render() {
		const { authError } = this.props;

		return (
			<>
				<Col lg="5" md="7">
					<Card className="bg-secondary shadow border-0">
						<div style={{ display: 'flex', marginTop: "20px", marginLeft: "20px" }}>
							<Link to={"auth/login"}	>
								<i
									className='fas fa-long-arrow-alt-left '
									style={{ fontSize: '25px', color: "#5E72E4" }}
								/>
							</Link>
						</div>
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center text-muted mb-4">
								<small>Forgot Password</small>
							</div>
							<Form onSubmit={this.resetPassword} role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="email"
											placeholder="Email"
											type="email"
											autoComplete="new-email"
										/>
									</InputGroup>
								</FormGroup>
								{authError ? (
									<h3 className="text-danger"> {authError}</h3>
								) : null}
								<div className="text-center">
									<Button className="my-4" color="primary">
										{/* {this.props.isLoad.isLoad==false ? 'no':'eyss'} */}

										{/* {JSON.stringify(this.props.isLoad.isLoad)=='false' ? 'no':'eyss'} */}

										{this.props.isLoad === false ? (
											"Reset Password"
										) : (
											<Spinner />
										)}
									</Button>

								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoad: state.auth.isLoad,
		uid: state.firebase.auth.uid,
		authError: state.auth.authError,
		raters: state.firestore.ordered.raters,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		resetPassword: email => dispatch(resetPassword(email)),
	};
};

export default compose(
	firestoreConnect([{ collection: "raters" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(ForgetPassword);
