import React from "react"
import { Slider, Switch } from 'antd';
import useMediaQuery from "hooks/useMediaQuery";
const SliderComponent = ({ question, value, onChange }) => {
  const isSmall = useMediaQuery('(max-width: 1240px)');

  const handleSliderChnage = (value) => {
    onChange(value)
  }

  function getMarks() {
    const marks = {}
    for (let i = 0; i <= 10; i++) {
      marks[i] = i.toString()
    }
    return marks
  }

  return (
    <>
      <h3>
        {question.text}
      </h3>
      {question.help && (
        <div>
          <i>
            Hint:{" "}
            {question.help}
          </i>
        </div>
      )}

      <Slider min={0}
        style={{ marginTop: "20px", marginBottom: "40px" }}
        max={10}
        defaultValue={value}
        onChange={handleSliderChnage}
        marks={getMarks()}
      />
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "25px", fontWeight: "bold" }} >
        <span >Strongly {isSmall && <br />}  Disagree </span>


        <span>Strongly {isSmall && <br />}  Agree </span>
      </div>

    </>
  )
}

export default SliderComponent