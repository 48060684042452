import React from "react";
import ReactDOM from "react-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./app";
import { Provider } from "react-redux";
import { store } from "./store";
import firebase from "./firebase";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
require("dotenv").config()

Sentry.init({
  dsn: "https://2e1afbdba3c5405fb244300b77d37565@o1307973.ingest.sentry.io/6576193",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_PROJECT_ID,
  tracesSampleRate: .5,
});

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance,
};
ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
