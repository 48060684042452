import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "raterRoutes.js";
import firebase from "firebase";
import Assessment from "views/examples/Assessment.js";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class Rater extends React.Component {
	myHeaderRef = React.createRef();
	componentDidUpdate(e) {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}
	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === "/rater") {
				return (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	render() {
		let { uid } = this.props;
		const user = firebase.auth().currentUser
		if (!uid) return <Redirect to="/auth/login" />;


		// const getHeaderText = () => {
		// 	const thisRater = this.props.raters?.find(con => con.id === uid);
		// 	const raterInvitation = this.props.invitations?.find((inv) => inv.email === thisRater?.email)
		// 	return raterInvitation?.assessmentName
		// }
		if (!this.props.history.location.pathname.includes("/rater/client/")) {
			localStorage.removeItem("assessment_title");
			localStorage.removeItem("no_render");
		}
		return (
			<>
				<Sidebar
					{...this.props}
					routes={routes}
					logo={{
						innerLink: "/rater/index",
						imgSrc: require("assets/img/brand/argon-react.png"),
						imgAlt: "...",
					}}
				/>
				<div className="main-content" ref="mainContent">
					<AdminNavbar
						myHeaderRef={this.myHeaderRef}
						{...this.props}
					// headerText={getHeaderText()}
					/>
					<Switch>
						{this.getRoutes(routes)}
						<Route
							exact
							path="/rater/client/:clientId/project/:projectId/assessment/:assessmentId"
							component={Assessment}
							key={1234}
						/>
						<Redirect from="*" to="/rater/assessments" />
						{this.myHeaderRef.current === null
							? ""
							: (this.myHeaderRef.current.text = "abc")}
						{/* <Redirect from="*" to="/rater/assessments" /> */}
					</Switch>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth.authError,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		invitations: state.firestore.ordered.invitations,
		assessments: state.firestore.ordered.assessments,
		raters: state.firestore.ordered.raters
	};
};
const mapDispatchToProps = dispatch => {
	return {
		// addAssessment: (ass, projectId, clientId) =>
		//   dispatch(addAssessment(ass, projectId, clientId)),
		// deleteAssessment: (ass, projectId, clientId) =>
		//   dispatch(deleteAssessment(ass, projectId, clientId)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "clients" },
		{ collection: "consultants" },
		{ collection: "invitations" },
		{ collection: "assessments" },
		{ collection: "raters" }
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(Rater);
